import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Site } from '../model/site';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  constructor(private http: HttpClient) {
  }

  getSites() {
    return this.http
      .get('/api/booking/resources/sites')
      .pipe(map((value: any[]) => value.map(value1 => new Site(value1))));
  }
}
