import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Tag } from '../model/tag';
import { UserWithTag } from '../model/user-with-tag';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  constructor(private http: HttpClient) {
  }

  // User

  getTags() {
    return this.http
      .get('/api/tag')
      .pipe(map((tags: undefined[]) => tags.map(tag => new Tag(tag))));
  }

  getUser() {
    return this.http
      .get('/api/tag/user')
      .pipe(map((user: undefined) => new UserWithTag(user)));
  }

  // Admin

  getUsers(): Observable<UserWithTag[]> {
    return this.http
      .get('/api/admin/tag/users')
      .pipe(
        map((users: undefined[]) => users.map(user => new UserWithTag(user)))
      );
  }

  toggleTag(user: UserWithTag, tag: Tag) {
    return this.http.patch(
      '/api/admin/tag/' + tag.id + '/user/' + user.id,
      null
    );
  }

  createTag(tagName: string) {
    return this.http
      .post('/api/admin/tag', tagName)
      .pipe(map(tag => new Tag(tag)));
  }

  renameTag(tag: Tag, newName: string) {
    return this.http.put('/api/admin/tag/' + tag.id, newName);
  }

  deleteTag(tag: Tag) {
    return this.http.delete('/api/admin/tag/' + tag.id);
  }
}
